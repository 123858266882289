import React from "react"
import styled from "styled-components"

export default function LinkArrow({className}) {
    return(
        <SVG className={className}
            width="14" height="14" 
            viewBox="0 0 15 15" fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M0.95283 4.83624L13.6841 0.592489C13.7848 0.558656 13.8929 0.553574 13.9964 0.577814C14.0998 0.602054 14.1944 0.654656 14.2696 0.72971C14.3448 0.804765 14.3975 0.899297 14.422 1.00269C14.4464 1.10608 14.4415 1.21423 14.4078 1.31499L10.1641 14.0462C10.1283 14.1544 10.061 14.2494 9.97087 14.319C9.88071 14.3886 9.77179 14.4297 9.65811 14.4369C9.54444 14.4442 9.43118 14.4173 9.3329 14.3597C9.23463 14.3021 9.15581 14.2165 9.10658 14.1137L6.43533 8.56249L0.88533 5.89374C0.78301 5.84433 0.69775 5.76552 0.640451 5.6674C0.583152 5.56928 0.556417 5.4563 0.563663 5.3429C0.57091 5.22951 0.611809 5.12085 0.681132 5.03082C0.750455 4.94079 0.845052 4.87348 0.95283 4.83749V4.83624Z" fill="#00529B"/>
        </SVG>
    )
}

const SVG = styled.svg`
    path {
        transition: all 0.3s;
    }
`