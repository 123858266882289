import chapter1Data from "./chapter1"
import chapter2Data from "./chapter2"
import chapter3Data from "./chapter3"
import chapter4Data from "./chapter4"
import chapter5Data from "./chapter5"
import chapter6Data from "./chapter6"

const coursePagesData = {
    1: chapter1Data,
    2: chapter2Data,
    3: chapter3Data,
    4: chapter4Data,
    5: chapter5Data,
    6: chapter6Data,
}

export default coursePagesData
