import React from "react";
import styled from "styled-components";

export default function Leave({ color, className }) {
  return (
    <Wrapper
      width="22"
      height="22"
      viewBox="0 0 22 22"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3503 0.00572336L11.3321 0.00593934C8.63682 0.0677624 6.1038 1.1504 4.19981 3.05444C0.327041 6.92716 0.14357 13.1134 3.64924 17.2055L0.5727 20.2819C0.256408 20.5982 0.256404 21.111 0.572691 21.4273C0.888975 21.7436 1.40177 21.7436 1.71806 21.4273L4.79445 18.3509C6.68181 19.9707 9.05927 20.8542 11.5727 20.8542C14.3576 20.8542 16.9763 19.7696 18.9455 17.8003C20.849 15.897 21.9315 13.3646 21.994 10.6698L21.9994 1.0011C21.9997 0.448389 21.5516 0.000240973 20.9989 0.000538013L11.3503 0.00572336ZM20.3744 10.6412C20.3195 12.9143 19.4056 15.0495 17.8002 16.6549C16.1367 18.3183 13.9251 19.2344 11.5727 19.2344C9.49235 19.2344 7.52238 18.5174 5.94398 17.2013L7.78042 15.3649H15.0346C15.4819 15.3649 15.8445 15.0023 15.8445 14.555C15.8445 14.1077 15.4819 13.7451 15.0346 13.7451H9.40035L11.2167 11.9287H15.0346C15.4819 11.9287 15.8445 11.5661 15.8445 11.1188C15.8445 10.6715 15.4819 10.3089 15.0346 10.3089H12.8365L15.0809 8.06446C15.3972 7.74817 15.3972 7.23537 15.0809 6.91909C14.7646 6.6028 14.2518 6.6028 13.9355 6.91909L11.6911 9.16353L11.6911 6.96537C11.6911 6.51807 11.3285 6.15547 10.8812 6.15547C10.4339 6.15547 10.0713 6.51809 10.0713 6.96539L10.0713 10.7833L8.25497 12.5997L8.25493 6.96538C8.25492 6.51808 7.89232 6.15547 7.44502 6.15547C6.99772 6.15547 6.63511 6.51809 6.63511 6.96539L6.63516 14.2195L4.79909 16.0556C1.92273 12.5991 2.10436 7.44058 5.34513 4.19981C6.95107 2.59392 9.08697 1.67991 11.3604 1.62554L20.3793 1.62068L20.3744 10.6412Z"
        fill={color}
      />
    </Wrapper>
  );
}

const Wrapper = styled.svg`
`;
