import React from "react"
import styled from "styled-components"
import { COLORS } from "../../constants"

export default function LinkIcon({ width = "33px", className = null }) {
    return (
        <Wrapper
            width={width}
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M30.8222 2.64648L29.6739 1.6307C26.9995 -0.740809 22.9043 -0.494915 20.5336 2.18015L17.2473 5.88775C16.5055 6.72471 16.5826 8.00964 17.4192 8.75209L17.7277 9.02577C18.5672 9.77008 19.8482 9.69323 20.593 8.85359L23.8792 5.14611C24.6122 4.31984 25.882 4.2438 26.7088 4.97567L27.8557 5.99319C28.6827 6.72599 28.7606 7.99557 28.0265 8.82231L20.8406 16.9288C20.2324 17.616 19.2372 17.8013 18.4205 17.3793C17.5273 16.9181 16.4381 17.1207 15.7712 17.8728L15.7152 17.9361C15.264 18.445 15.0758 19.1206 15.1991 19.7896C15.3224 20.4584 15.7388 21.0225 16.3413 21.3374C17.2943 21.8355 18.3235 22.0766 19.3425 22.0766C21.1483 22.0766 22.9228 21.3195 24.1872 19.8938L31.3726 11.7883C33.7406 9.1168 33.4942 5.01636 30.8222 2.64648Z" />
            <path d="M15.5788 24.2499L15.2696 23.976C14.4321 23.2339 13.1468 23.3119 12.4049 24.1491L9.12006 27.8563C8.38668 28.6823 7.1178 28.7589 6.29176 28.0275L5.1432 27.0086C4.316 26.276 4.23834 25.0075 4.97265 24.1805L12.1583 16.0746C12.7541 15.4018 13.7343 15.2094 14.5422 15.6063C15.4706 16.0625 16.598 15.8407 17.2854 15.065L17.3112 15.0359C17.7527 14.5374 17.9385 13.8758 17.8211 13.2206C17.7037 12.5654 17.2998 12.0095 16.7128 11.6954C14.0587 10.2747 10.8103 10.856 8.81313 13.1097L1.62698 21.214V21.2141C-0.74139 23.8856 -0.495031 27.9859 2.17643 30.3548L3.32475 31.372C4.55515 32.4629 6.0883 32.9987 7.61633 32.9987C9.40606 32.9987 11.1884 32.2632 12.4665 30.8219L15.7519 27.1147C16.4951 26.2754 16.4189 24.9947 15.5788 24.2499Z" />
        </Wrapper>
    )
}

const Wrapper = styled.svg`
    fill: ${COLORS.blue};
`
